import React from 'react'

import {
  Container,
  Wrapper,
  Flex,
  SectionTitle,
  Disclaimer,
  Person,
} from './styles'

const Credits = ({ credits }) => (
  <Container>
    <Wrapper>
      <Flex bg="black" color="white">
        <Disclaimer>
          <bold>NOTE:</bold> All the children’s names have been changed to
          protect their identities. NASP stories about children are not matched
          with their portraits.
        </Disclaimer>
      </Flex>
      <Flex>
        {credits && <SectionTitle>Credits</SectionTitle>}
        {credits &&
          credits.length > 0 &&
          credits.map(({ person, role, link }) => (
            <Person>
              <p>
                <bold>{person.name},</bold> {role}
              </p>
              <p>{link}</p>
            </Person>
          ))}
      </Flex>
    </Wrapper>
  </Container>
)

export default Credits
