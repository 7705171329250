import styled from 'styled-components'
import { color } from 'styled-system'

export const Container = styled.div`
  border-top: 1px solid ${props => props.theme.colors.black};
`

export const Wrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 32rem) {
    flex-direction: column;
  }
`

export const Flex = styled.div`
  width: 50%;
  padding: 4rem;
  ${color};
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 48rem) {
  }
  @media only screen and (max-width: 32rem) {
    padding: 4rem 2rem;
    width: 100%;
  }
`

export const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.serif};
  font-weight: 300;
  font-size: 3rem;
  margin-bottom: 0;
  margin-top: 0;
  @media only screen and (max-width: 48rem) {
    font-size: 2.25rem;
  }
`

export const Disclaimer = styled.div`
  align-self: flex-end;
  max-width: 24em;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 300;
  bold {
    font-weight: 500;
  }
  @media only screen and (max-width: 48rem) {
    align-self: initial;
    font-size: 1rem;
  }
`

export const Person = styled.div`
  font-size: 1.375rem;
  font-weight: 300;
  margin-top: 2rem;
  bold {
    font-weight: 500;
  }
  p {
    margin: 0;
  }
  a {
    color: inerit;
  }
  @media only screen and (max-width: 48rem) {
    font-size: 1rem;
  }
`
