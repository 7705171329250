import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;
  overflow-x: hidden;
  .svg-inline--fa {
    display: initial;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  // transition: 0.375s;
  @media only screen and (max-width: 62rem) {
    width: calc(100vw + 40rem);
    margin-left: ${props => (props.expand ? '0' : 'calc(-32rem)')};
  }
  @media only screen and (max-width: 38rem) {
    width: calc(200vw - 6rem);
    margin-left: ${props => (props.expand ? '0' : 'calc(-100vw + 6rem)')};
  }
`

export const Block = styled.div`
  transition: 0.25s;
  div div {
    ${props => props.blur && `filter: blur(0.25rem);`};
  }
`

export const ToggleBack = styled.div`
  padding: 0.75rem 1.5rem;
  transform: translateY(-50%) rotate(90deg) translateY(100%);
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  border: ${props => props.theme.colors.black};
  display: none;
  position: fixed;
  top: 50%;
  z-index: 10;
  @media only screen and (max-width: 62rem) {
    display: block;
    left: 35rem;
  }
  @media only screen and (max-width: 48rem) {
    left: auto;
    right: 0;
    transform: translateY(-50%) rotate(90deg) translateY(-100%);
  }
`
